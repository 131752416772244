.owl-stage {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  display: box;
}

.slider {
  position: relative;
}

#owl-gallery {
  &.owl-carousel {
    position: relative;
    height: 50%;
    z-index: 0;

    .owl-slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      min-height: 250px;
      width: 100%;

      overflow: hidden;

      a {
        text-decoration: none;
      }

      .owl-lazy {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 30vh;
        display: flex;
        align-items: flex-end;

        &:hover {
          .owl-slide-overlay {
            opacity: 1;
          }
        }
      }

      .owl-slide-overlay {
        background-color: $grey;
        padding: 2rem 3rem;
        width: 100%;
        opacity: 0;
        transition: 0.3s;

        &__title {
          @include fluid-type($min_width, $max_width, (18px), 18px, 1.25, 1.25);
          color: $primary;
          text-transform: uppercase;
        }

        &__description {
          @include fluid-type($min_width, $max_width, (16px), 16px, 1.2, 1.2);
          color: $white;
          font-weight: 300;
        }
      }
    }

    .owl-nav {
      .owl-prev, .owl-next {
        position: absolute;
        top: 50%;
        padding: 20px!important;
        background-color: $primary;
        line-height: 20px;
        color: $white;
        margin-top: -30px;
        outline: none;
      }

      .owl-prev {
        left: 0;
        transform: rotate(-180deg);
      }

      .owl-next{
        right: 0;
      }

      .disabled {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #owl-gallery {
    &.owl-carousel {
      .owl-slide {
        .owl-lazy {
          height: 50vh;
        }
      }
    }
  }
}