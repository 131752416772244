.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-pane {
  //transition: 0.3s;
}

.maps-wrapper {
  width: calc(100% + 30px);
  height: 100%;
  min-height: 640px;
  margin: 0 -15px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  //transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.maps-wrapper-overlay {
  position: absolute;
  z-index: 9999;
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &-close {
    opacity: 0;
    visibility: hidden;
  }
}

#mainMap {
  width: 100%;
  height: 100%;
}

.maps-marker {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

//leaflet popup styling
.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 0;
}

.leaflet-popup-content {
  margin: 0;
  p {
    margin: 0;
  }
}

.leaflet-container a.leaflet-popup-close-button {
  width: 24px;
  height: 24px;
  padding: 0;
  font: 24px/22px Tahoma, Verdana, sans-serif;
  color: $white!important;
  transition: 0.4s;
  z-index: 0;
  text-decoration: none!important;
  display: none;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: $primary;
    z-index: -1;
    transition: 0.4s;
  }

  &:hover {
    color: $white;
    transform: rotate(45deg);
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.popup-info {
  width: 100%;
  img {
    width: 100%;
  }
  &__body {
    padding: 1rem;
    //@extend %dagny-pro;
    @include fluid-type($min_width, $max_width, (18px/1.2), 18px, 1.4/1.2, 1.4);
    color: $body-color;

    a {
      color: $primary;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-only(xs) {
  .leaflet-popup-content {
    width: 250px!important;
    min-width: auto!important;

  }
}