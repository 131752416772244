.gallery {
    @include clearfix;
    overflow: hidden;

    a {
        display: inline-block;
        float: left;
        position: relative;
        width: 20%;
        text-decoration: none;
        border: none!important;

        figure {
            @extend .vischmarkt49-icons;
            @extend .vischmarkt49-icons-search;
            &:after {
                content: "";
                background-color: rgba(#f7f7f7, 0.75);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                z-index: 1;
                opacity: 1;
                pointer-events: none;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

                -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                z-index: 1;
                opacity: 0;
                pointer-events: none;
            }
        }
        &:hover {
            figure {
                &:after {
                    opacity: 0;
                }
                &:before {
                    @include fluid-type($min_width, $max_width, (28px/1.2), 28px, 1.5/1.2, 1.5);
                    opacity: 1;
                }
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 33.333%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }

        &.gallery--product {
            a {
                width: 50%;
                &:first-child {
                    width: 100%;
                }
            }
        }
    }
    &.more {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 33.333%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }

            @include media-breakpoint-up(lg) {
                width: 20%;
            }
        }

        .gallery--left {
            transform: translateX(1px);
            a {
                width: 33.33%;
                &:nth-child(1),&:nth-child(2) {
                    width: 50%;
                }
            }
        }

        .gallery--right {
            transform: translateX(-1px);
            a {
                width: 33.33%;
                &:nth-child(4),&:nth-child(5) {
                    width: 50%;
                }
            }
        }
    }
}