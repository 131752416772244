blockquote {
  position: relative;
  margin: 1.5rem 0;
  display: inline-block;
  text-align: center;

  p {
    margin: 0;
    &:first-of-type {
      &:before {
        content: "“";
        margin-right: 0.35rem;
        display: inline-block;
      }
    }
    &:last-of-type {
      &:after {
        content: "”";
        margin-left: 0.35rem;
        display: inline-block;
      }
    }
  }
}

.blockquote__author {
  margin-top: 0.5rem;
  @include fluid-type($min_width, $max_width, (25px/1.6), 25px, 1.25, 1.25);
}