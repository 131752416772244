//circle element
.circle-element {
  margin: 1rem auto;
  width: 120px;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
    height: 120px;
    @include fluid-type($min_width, $max_width, (32px/1.6), 32px, 1, 1);
    color: $primary;
    border: 1px solid $primary;
    border-radius: 50%;
  }
}

@include media-breakpoint-up(md) {
  .circle-element {
    margin: 2rem auto;
    width: 160px;
    &__header {
      margin-bottom: 2.25rem;
      height: 160px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .circle-element {
    width: 200px;
    &__header {
      height: 200px;
    }
  }
}