.image-header {
  position: relative;
  margin-top: 52px;
  z-index: 0;

  &-overlay {
    height: 100%;
    width: 100%;
    display: flex;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    flex: 1;
    //fix for width calculation owl carousel
    min-width: 100%;
    border-bottom: 1px solid $body-color;
  }

  &__cta {
    grid-column: header-cta;
    display: none;
  }

  &__background {
    position: relative;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+20,000000+100&0.2+40,0+100 */
      background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    text-align: center;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0.5rem;
    }
  }

  &__subtitle {
    text-align: center;
    @include fluid-type($min_width, $max_width, (32.5px/1.5), 32.5px, 1, 1);
    font-weight: 300;

    &--arrow {
      &:before {
        display: inline-block;
        margin-left: 1rem;
        width: 44px;
        height: 44px;
        min-width: 44px;
        border: 4px solid;
        border-radius: 22px;
        //@extend .gelrekoffie-icons;
        //@extend .gelrekoffie-icons-arrow-next;
        font-size: 11px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        order: 2;
        transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      }

      &:hover {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__button {
    margin-top: 2vw;
    text-align: center;
    display: none;
  }

  //specific styling for homepage
  &--home {
    .image-header__background {
      //depends on image ratio
      min-height: 34vw;
    }
  }
}

@include media-breakpoint-up(sm) {
  .image-header {
    margin-top: 72px;
  }
}

@include media-breakpoint-up(md) {
  .image-header {
    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $white;
    }
    &__content {
      padding-bottom: 2rem;
      border-bottom: none;
    }
    &__button {
      display: block;
    }
    &--home {
      .image-header__background {
        height: 41vw;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-header {
    margin-top: 98px;
    &--home {
      .image-header__background {
        height: 41vw;
        max-height: 800px;
      }
    }
  }
}