ul.list--default, ul.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list--bullet {
  padding: 0;
  margin-bottom: 2rem;
  list-style: none;
  padding-left: 1.25rem;
  li {
    position: relative;
    &:before {
      content: "\25A0";
      margin-right: 0.5rem;
      margin-left: -0.75rem;
      font-size: 7px;
      vertical-align: middle;
    }
  }
}

ul.list--check {
  padding-left: 1.25rem;
  @include fluid-type($min_width, $max_width, (14px/1.25), 14px, (1.6/1.25), 1.6);
  font-weight: bold;
  text-transform: uppercase;
  li {
    position: relative;
    margin-bottom: 0.65rem;
    &:before {
      position: absolute;
      @extend .vischmarkt49-icons;
      @extend .vischmarkt49-icons-check;
      margin-left: -1.25rem;
      color: $primary;
    }
  }
}

ul.list--inline {
  li {
    display: inline-block;
    margin-bottom: 0;
  }
}