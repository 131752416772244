body {
  @extend %default-font;
  @include fluid-type($min_width, $max_width, 14px, 18px, 1.6, 1.6);
  font-weight: 400;
  color: $body-color;
}

h1,.h1,h2,.h2, h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 2rem;

  a i {
    @include fluid-type($min_width, $max_width, 18px, 22px, 1.6, 1.6);
  }
}

.text-gray {
  color: fade_out($body-color, 0.30);
}

h1,.h1 {
  @extend %abuget;
  @include fluid-type($min_width, $max_width, (70px), 70px, 1, 1);
  font-weight: 400;
}

h2,.h2 {
  @extend %abuget;
  @include fluid-type($min_width, $max_width, (80px/1.8), 80px, 1, 1);
  font-weight: 400;
}

h3,.h3 {
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (20px/1.6), 20px, 1, 1);
  font-weight: 300;
  text-transform: uppercase;
}

h4,.h4,h5,.h5,h6,.h6 {
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (18px/1.6), 18px, 1, 1);
  font-weight: 700;
}

.bottom-line:after {
  content: "";
  background-color: $primary;
  height: 5px;
  width: 60%;
  display: block;
  margin-left: 20%;
  margin-top: -5px;
}