.phone {
  margin-right: 1rem;
  @include fluid-type($min_width, $max_width, 16px, 24px, 1.8, 1.8);

  a {
    color: $body-color;
    text-decoration: none;
  }

  i {
    vertical-align: middle;
  }

}