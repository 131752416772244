.share {
  position: relative;
  margin-left: 1rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    text-align: center;

    li {
      display: inline-block;
      padding: 0;
      margin-right: 8px;
      text-align: center;

      &:before {
        display: none;
      }

      a {
        @include fluid-type($min_width, $max_width, (28px), 28px, 1.6, 1.6);
        color: $blue;

        i {
          background-color: transparent;
          display: inline-block;
          margin-right: 4px;

          width: 24px;
          height: 24px;

          text-align: center;
        }

      }
    }
  }
}