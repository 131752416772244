.topbar {
  display: flex;
  align-items: center;

  .tripadvisor-wrapper {
    height: 28px;
    width: 182px;
    img {
      margin-right: 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .topbar {
    height: 90px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 8px solid $primary
  }
}

@include media-breakpoint-up(xxl) {
  .topbar {
    .tripadvisor-wrapper {
      img {
        margin-right: 2rem;
      }
    }
  }
}