section {
  position: relative;
  overflow: hidden;

  .animated {
    opacity: 0;
    transition: 0.7s;
    transition-delay: 0.1s;
    transform: translateX(-1rem);
  }

  &.inview {
    .animated {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .section-image {
    position: relative;
    min-height: 30vh;
    margin: 0 -15px;
    &.section-image-maps {
      min-height: 100vh;
    }
  }

  &.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  &.border--bottom {
    border-bottom: 3px solid $light;
  }

  a {
    color: $body-color;
    text-decoration: none;
    outline: none;

    i {
      vertical-align: middle;
    }
  }

  a:not(.page-link):not(.btn):not(.nav-link):not(.no-link-style):not(.leaflet-control-zoom-in):not(.leaflet-control-zoom-out) {
    color: $primary;
    text-decoration: underline;
  }

  ul:not(.specs):not(.pagination):not(.no-list-style) {
    padding: 0;
    margin-bottom: 2rem;
    list-style: none;
    padding-left: 1.25rem;

    li {
      position: relative;

      &:before {
        content: "\25A0";
        margin-right: 0.5rem;
        margin-left: -0.75rem;
        font-size: 7px;
        vertical-align: middle;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  section {
    .section-image {
      min-height: 100%;
      &.section-image-maps {
        min-height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  section {
    .section-wrapper {
      position: relative;
    }
  }
}