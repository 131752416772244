#owl-usp {
  width: 100%;
  @include fluid-type($min_width, $max_width, 11, 18px, 1, 1);
  font-weight: 400;
  text-transform: uppercase;

  .usp-item {
    padding: 0;
    text-align: center;
    &:before {
      content: "\2713";
      margin-right: 0.25rem;
      @include fluid-type($min_width, $max_width, 16px, 22px, 1, 1);
      font-weight: 700;
    }
  }
}

@include media-breakpoint-down(md) {
  .usp-wrapper {
    background-color: $body-color;
    color: $white;
  }
}

@include media-breakpoint-up(lg) {
  #owl-usp {
    .owl-item {
      padding: 0.625rem 0;
    }
  }
}