//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";

@font-face {
  font-family: 'abugetregular';
  src: url('../fonts/abuget-webfont.woff2') format('woff2'),
  url('../fonts/abuget-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//// acumin pro font
%acumin-pro {
  font-family: acumin-pro, sans-serif;
  font-style: normal;
}
// light: 300
// regular: 400
// bold: 700

//// abuget font
%abuget {
  font-family: 'abugetregular', sans-serif;
  font-style: normal;
}
// regular: 400

%default-font {
  @extend %acumin-pro;
}


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1400px
);


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .5),
                        2: ($spacer),
                        3: ($spacer * 1.5),
                        4: ($spacer * 2),
                        5: ($spacer * 2.5)
                ),
                $spacers
);