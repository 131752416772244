body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

body {
  margin-top: 74px;
}

@include media-breakpoint-up(sm) {
  body {
    margin-top: 96px;
  }
}

@include media-breakpoint-up(md) {
  body {
    margin-top: 142px;
  }
}

@include media-breakpoint-up(lg) {
  body {
    margin-top: 162px;
  }
}

@include media-breakpoint-up(xl) {
  body {
    margin-top: 188px;
  }
}

header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);
}

.sidebar {
  position: relative;
}

table, .table {
  margin-bottom: 1.5rem;
  border-spacing: 0;
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($primary, 0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.padding-top--normal {
  padding-top: 1rem;
}

.padding-bottom--normal {
  padding-bottom: 1rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 4rem;
}

.padding-bottom--larger {
  padding-bottom: 4rem;
}

.margin-top--normal {
  margin-top: 1rem;
}

.margin-bottom--normal {
  margin-bottom: 1rem;
}

.margin-offset--normal {
  margin-top: -1rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 4rem;
}

.margin-bottom--larger {
  margin-bottom: 4rem;
}

@include media-breakpoint-up(md) {
  .padding-top--normal {
    padding-top: 1rem;
  }

  .padding-bottom--normal {
    padding-bottom: 1rem;
  }

  .padding-top--large {
    padding-top: 2rem;
  }

  .padding-bottom--large {
    padding-bottom: 2rem;
  }

  .padding-top--larger {
    padding-top: 4rem;
  }

  .padding-bottom--larger {
    padding-bottom: 4rem;
  }

  .margin-top--normal {
    margin-top: 1rem;
  }

  .margin-bottom--normal {
    margin-bottom: 1rem;
  }

  .margin-offset--normal {
    margin-top: -1rem;
  }

  .margin-top--large {
    margin-top: 2rem;
  }

  .margin-bottom--large {
    margin-bottom: 2rem;
  }

  .margin-offset--large {
    margin-top: -2rem;
  }

  .margin-top--larger {
    margin-top: 4rem;
  }

  .margin-bottom--larger {
    margin-bottom: 4rem;
  }

  .margin-offset--larger {
    margin-top: -4rem;
  }
}

@include media-breakpoint-up(lg) {
  .padding-top--normal {
    padding-top: 1rem;
  }

  .padding-bottom--normal {
    padding-bottom: 1rem;
  }

  .padding-top--large {
    padding-top: 2rem;
  }

  .padding-bottom--large {
    padding-bottom: 2rem;
  }

  .padding-top--larger {
    padding-top: 6rem;
  }

  .padding-bottom--larger {
    padding-bottom: 6rem;
  }

  .margin-top--normal {
    margin-top: 1rem;
  }

  .margin-bottom--normal {
    margin-bottom: 1rem;
  }

  .margin-offset--normal {
    margin-top: -1rem;
  }

  .margin-top--large {
    margin-top: 2rem;
  }

  .margin-bottom--large {
    margin-bottom: 2rem;
  }

  .margin-offset--large {
    margin-top: -2rem;
  }

  .margin-top--larger {
    margin-top: 6rem;
  }

  .margin-bottom--larger {
    margin-bottom: 6rem;
  }

  .margin-offset--larger {
    margin-top: -6rem;
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }

  &-fixed {
    background-attachment: fixed;
    background-size: 100%!important;
    background-repeat: no-repeat;
    background-position: top!important;
  }
}

.back-to-top {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0.5rem 1rem!important;
  z-index: 9;
  i {
    display: block;
    transform: rotate(-90deg);
  }
}

@include media-breakpoint-up(xl) {

  .padding-top--normal {
    padding-top: 2rem;
  }

  .padding-bottom--normal {
    padding-bottom: 2rem;
  }

  .padding-top--large {
    padding-top: 4rem;
  }

  .padding-bottom--large {
    padding-bottom: 4rem;
  }

  .padding-top--larger {
    padding-top: 8rem;
  }

  .padding-bottom--larger {
    padding-bottom: 8rem;
  }

  .margin-top--normal {
    margin-top: 2rem;
  }

  .margin-bottom--normal {
    margin-bottom: 2rem;
  }

  .margin-offset--normal {
    margin-top: -2rem;
  }

  .margin-top--large {
    margin-top: 4rem;
  }

  .margin-bottom--large {
    margin-bottom: 4rem;
  }

  .margin-offset--large {
    margin-top: -4rem;
  }

  .margin-top--larger {
    margin-top: 8rem;
  }

  .margin-bottom--larger {
    margin-bottom: 8rem;
  }

  .margin-offset--larger {
    margin-top: -8rem;
  }

  .layout--max-width {
    margin: 0 auto;
    max-width: 80%;
  }
}

@include media-breakpoint-up(xxl) {

  .padding-top--normal {
    padding-top: 4rem;
  }

  .padding-bottom--normal {
    padding-bottom: 4rem;
  }

  .padding-top--large {
    padding-top: 6rem;
  }

  .padding-bottom--large {
    padding-bottom: 6rem;
  }

  .padding-top--larger {
    padding-top: 10rem;
  }

  .padding-bottom--larger {
    padding-bottom: 10rem;
  }

  .margin-top--normal {
    margin-top: 4rem;
  }

  .margin-bottom--normal {
    margin-bottom: 4rem;
  }

  .margin-offset--normal {
    margin-top: -4rem;
  }

  .margin-top--large {
    margin-top: 6rem;
  }

  .margin-bottom--large {
    margin-bottom: 6rem;
  }

  .margin-offset--large {
    margin-top: -6rem;
  }

  .margin-top--larger {
    margin-top: 10rem;
  }

  .margin-bottom--larger {
    margin-bottom: 10rem;
  }

  .margin-offset--larger {
    margin-top: -10rem;
  }
}

#owl-partners {
  &.owl-carousel {
    .owl-slide {
      .owl-lazy {
        &:after {
          content: "";
          height: 1px;
          width: 70%;
          background-color: #d7d7d7;
          position: absolute;
          bottom: 0;
          left: 15%;
        }

        &:before {
          content: "";
          width: 1px;
          height: 80%;
          background-color: #d7d7d7;
          position: absolute;
          top: 10%;
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  #owl-header {
    &.owl-carousel {
      .owl-slide {
        .owl-lazy {
          height: 50vh;
        }
      }
    }
  }

}

.logo-wrapper {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  img {
    width: 100%;
  }
}

.col-md-4-square {
  min-height: calc(100vw/3);
}

.square:before{
  content: "";
  display: block;
  padding-top: 100%;  /* initial ratio of 1:1*/
}

.menucard-item {
  min-height: 46vw;
  padding: 2rem 2.5rem;
  display: flex;
  align-items: flex-end;
  color: $white!important;
  text-decoration: none!important;

  @include media-breakpoint-up(md) {
    min-height: 20vw;
  }

  @include media-breakpoint-up(xl) {
    min-height: 16vw;
  }

  .bg-progressive {
    * {
      backface-visibility: hidden;
      transform: rotate(0) scale(1.01);
      transition: 0.3s;
    }
  }

  &:hover {
    .bg-progressive {
      * {
        transform: rotate(2deg) scale(1.07);
      }
    }
  }
}

.menucard-item-overlay {
  z-index: 0;
  &__title {
    @extend %abuget;
    @include fluid-type($min_width, $max_width, (80px/1.4), 80px, 0.5, 0.5);
    font-weight: 400;
  }
  &__link {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.google-reviews {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  &-score {
    margin-right: 0.35rem;
    color: #e7711b;
  }
  &-label {
    margin-left: 0.35rem;
    color: #11089b;
    white-space: nowrap;
  }
  &-score, &-label {
    font-size: 15px;
    text-decoration: none;
  }
}

#star-rating-wrapper {
  margin: 30px 0;

  .rating-value {
    margin-top: 10px;
    font-size: 30px;
  }

  .star-rating-logo {
    margin: 20px 0 0;
    img {
      max-width: 180px;
      width: 100%;
      height: auto;
    }
  }
}

.star-rating-wrapper {
  position: relative;
  height: 40px;
  width: 200px;
  margin: 4px auto 0 auto;

  ul.star-rating-group {
    padding: 0;
    margin: 0;
    height: 40px;
    width: 0;
    overflow: hidden;
    list-style: none;
    transition: all ease-in-out 0.8s;
    li {
      display: inline-block;
      position: absolute;
      height: 40px;
      width: 40px;
      &:nth-child(2) {
        left: 40px;
      }
      &:nth-child(3) {
        left: 80px;
      }
      &:nth-child(4) {
        left: 120px;
      }
      &:nth-child(5) {
        left: 160px;
      }

      i.star {
        position: relative;

        display: block;
        width: 0;
        height: 0;

        margin-left: .9em;
        margin-right: .9em;
        margin-bottom: 1.2em;

        border-right: .3em solid transparent;
        border-bottom: .7em solid #e7711b;
        border-left: .3em solid transparent;

        /* Controlls the size of the stars. */
        font-size: 16px;

        &:before, &:after {
          content: '';

          display: block;
          width: 0;
          height: 0;

          position: absolute;
          top: .6em;
          left: -1em;

          border-right: 1em solid transparent;
          border-bottom: .7em solid #e7711b;
          border-left: 1em solid transparent;

          transform: rotate(-35deg);
        }

        &:after {
          transform: rotate(35deg);
        }
      }
    }
    &:not(.default) {
      transform: translateY(-40px);
    }
    &.default {
      height: 40px;
      width: inherit;
      overflow: hidden;

      li {
        i.star {
          border-bottom-color: #eeeeee;
          &:before, &:after {
            border-bottom-color: #eeeeee;
          }
        }
      }
    }
  }
}
.star-rating--small {
  a {
    font-size: 13px;
  }
  .rating-value {
    font-size: 24px!important;
  }
  .star-rating-wrapper {
    width: 80px;
    height: 16px;
    ul.star-rating-group {
      height: 16px;
      li {
        height: 16px;
        width: 16px;
        &:nth-child(2) {
          left: 16px;
        }
        &:nth-child(3) {
          left: 32px;
        }
        &:nth-child(4) {
          left: 48px;
        }
        &:nth-child(5) {
          left: 64px;
        }

        i.star {
          font-size: 7px;
        }
      }
      &:not(.default) {
        transform: translateY(-16px);
      }
      &.default {
        height: 16px;
      }
    }
  }
}

.star-rating-wrapper {
  ul.star-rating-group[data-value='0'] {
    width: 0;
  }
  ul.star-rating-group[data-value='0.5'] {
    width: 20px;
  }
  ul.star-rating-group[data-value='1'] {
    width: 40px;
  }
  ul.star-rating-group[data-value='1.5'] {
    width: 60px;
  }
  ul.star-rating-group[data-value='2'] {
    width: 80px;
  }
  ul.star-rating-group[data-value='2.5'] {
    width: 100px;
  }
  ul.star-rating-group[data-value='3'] {
    width: 120px;
  }
  ul.star-rating-group[data-value='3.5'] {
    width: 140px;
  }
  ul.star-rating-group[data-value='4'] {
    width: 160px;
  }
  ul.star-rating-group[data-value='4.5'] {
    width: 180px;
  }
  ul.star-rating-group[data-value='5'] {
    width: 200px;
  }
}
.star-rating--small {
  .star-rating-wrapper {
    ul.star-rating-group[data-value='0'] {
      width: 0;
    }
    ul.star-rating-group[data-value='0.5'] {
      width: 8px;
    }
    ul.star-rating-group[data-value='1'] {
      width: 16px;
    }
    ul.star-rating-group[data-value='1.5'] {
      width: 24px;
    }
    ul.star-rating-group[data-value='2'] {
      width: 32px;
    }
    ul.star-rating-group[data-value='2.5'] {
      width: 40px;
    }
    ul.star-rating-group[data-value='3'] {
      width: 48px;
    }
    ul.star-rating-group[data-value='3.5'] {
      width: 56px;
    }
    ul.star-rating-group[data-value='4'] {
      width: 64px;
    }
    ul.star-rating-group[data-value='4.5'] {
      width: 72px;
    }
    ul.star-rating-group[data-value='5'] {
      width: 80px;
    }
  }
}


.star-rating-wrapper[data-delay='0.2'] {
  ul.star-rating-group {
    transition-delay: 0.2s;
  }
}
.star-rating-wrapper[data-delay='0.6'] {
  ul.star-rating-group {
    transition-delay: 0.6s;
  }
}
.star-rating-wrapper[data-delay='1'] {
  ul.star-rating-group {
    transition-delay: 1s;
  }
}