.nav-container {
  background-color: $white;
  width: 100%;
  z-index: 9;
  transition: 0.3s;

  .navbar {
    position: relative;
    margin: 0 auto;
    height: 52px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .navbar-brand {
      display: inline-flex;
      flex-direction: row;
      flex-grow: 1;
      margin-left: 1rem;
      font-size: 0;
      line-height: inherit;
      z-index: 0;
      img {
        width: auto;
        height: 36px;
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (22.5px/1.25), 19px, 1, 1);
      color: $body-color;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 96px;
      text-decoration: none;
      transition: 0.3s;

      &:before {
        content: "";
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        background-color: $body-color;
        transition: 0.3s;
      }
      &:not(.btn){
        padding: 0 0.8rem;
        color: $body-color;
      }
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        display: none;
      }
      > a {
        &:before {
          width: 100%;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin-left: 1rem;
          width: 200px;

          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            padding: 0.5rem 0;
            break-inside: avoid;

            a {
              position: relative;
              color: $body-color;
              text-decoration: none;
              transition: 0.3s;

              &:before {
                content: "";
                height: 1px;
                border-radius: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                background-color: $body-color;
                transition: 0.3s;
              }
            }

            &.active, &:hover {
              a {
                &:before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        color: $primary;
        &:before {
          background-color: $primary;
          width: 100%;
        }
      }
    }

    //open submenu
    &:hover {
      > a:not(.btn) {
        color: $primary;
        &:before {
          background-color: $primary;
          width: 100%;
        }
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

#nav-secundary {
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 0 0 1rem;

  > li {
    display: inline-block;
    height: 100%;
    > a {
      display: flex;
      font-weight: 600;
      line-height: 40px;
      text-decoration: none;

      align-items: center;

      &.btn {
        padding: 1rem 2rem;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      height: 72px;
      .navbar-brand {
        img {
          height: 47px;
        }
      }
    }
  }

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        padding: 0 2rem;
        line-height: 64px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    .nav-container {
      &:not(.sticky) {
        position: relative;
        background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  .nav-container {
    .navbar {
      height: 98px;
    }
  }

  ul.nav-desktop {

    > li {
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }
}

ul.custom-navigation {
  list-style: none;
  padding: 0;
  position: absolute;
  right: 3vw;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  li {
    display: block;
    margin-bottom: 1vw;
    a {
      background-color: $white;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 10px;
    }
    &.active {
      a {
        background-color: $primary;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  ul.custom-navigation {
    display: block;
  }
}