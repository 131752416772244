//disabled bootstrap buttons and add custom style
.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  @extend %default-font;
  //@include fluid-type($min_width, $max_width, (20px/1.2), 20px, 1.6/1.2, 1.6);
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  @include transition(0.3s);

  &--shadow {
    box-shadow: 0 0 15px 4px rgba(0,0,0,.10);
  }

  &--default {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }

    &--outline {
      background-color: transparent;
      color: $primary;
      border: 1px solid $primary;

      &:hover {
        background-color: darken($primary,5%);
        color: $white;
        text-decoration: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 0.5rem 1.75rem;
  }
}