$white:         #fff;
$grey:          #182026;
$black:         #000;
$blue:          #1877f2;

$light:         #f7f7f7;
$dark:          $grey;
$yellow:        #eaa748;

$primary:       $yellow;
$secondary:     $blue;

$body-color:    $grey;